:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-color: #f8f9fa;
  --text-color: #333;
  --border-color: #ced4da;
  --max-content-width: 1200px;
}

.contract-preview {
  border: 1px solid #ccc;
  padding: 20px;
  font-family: sans-serif;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}.contract-preview h1, /* Center these */.contract-preview h2 {
  text-align: center;
}.contract-preview h3 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}.contract-preview div[contenteditable] {
  white-space: pre-wrap;
  min-height: 400px;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: white;
  overflow-y: auto;
  border-radius: 4px;
  text-align: left;  /* Align content left */
  padding-left: 20px; /* Add left margin */
}.contract-preview h2 {
  margin-top: 20px;
  color: #555;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}.contract-preview p {
  margin-bottom: 10px;
}

/* Styles for the signatures section */.contract-preview.signatures {
  margin-top: 30px;
  text-align: center;
}.contract-preview.signatures span {
  display: inline-block;
  width: 45%;
  vertical-align: top;
}.contract-preview.signatures span:first-child {
  text-align: left;
}.contract-preview.signatures span:last-child {
  text-align: right;
}.contract-preview.signatures hr {
  width: 80%;
  margin: 10px auto;
  border: 0;
  border-top: 1px solid #ccc;
}


.modal-cancel-button,
.modal-save-button {
  margin-left: 10px;
  /* Space between buttons */
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-cancel-button {
  background-color: #ddd;
}

.modal-save-button {
  background-color: #4CAF50;
  /* Green */
  color: white;
}

.edit-button {
  /* Style the edit button */
  margin-top: 10px;
  /* Adjust as needed */
  padding: 5px 10px;
  background-color: lightblue;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* UploadTXT.css or SignInForm.css */

.upload-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* Align items to the top */
  min-height: 100vh;
  /* Ensure full viewport height */
  margin: 0;
  /* Remove default margins */
  padding: 40px;
  /* Add some padding */
  background-color: #f4f4f4;
  /* Light background */
  font-family: 'Tahoma', sans-serif;
  /* Consistent font */
}

.upload-card {
  width: 800px;
  /* Adjust as needed */
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  text-align: center;
}

.upload-card h2 {
  font-size: 28px;
  /* Slightly larger heading */
  color: #333;
  font-weight: bold;
  margin-bottom: 20px;
}

.upload-card p {
  font-size: 16px;
  color: #777;
  margin-bottom: 20px;
}

.file-upload {
  margin-bottom: 20px;
}

.file-upload input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.upload-button,
.generate-pdf-button {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.upload-button:hover,
.generate-pdf-button:hover {
  background-color: #0056b3;
}

.progress-bar {
  width: 100%;
  background-color: #eee;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 10px;
}

.progress-bar-fill {
  background-color: #007bff;
  height: 10px;
}

.contract-preview {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  /* Softer background */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  /* Lighter shadow */
}

.contract-preview h3 {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.contract-preview div[contenteditable] {
  width: 100%;
  min-height: 400px;
  /* Adjust as needed */
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Courier New', monospace;
  /* Use a monospace font */
  white-space: pre-wrap;
  overflow-y: auto;
  background-color: #fff;
}

.download-buttons {
  margin-top: 20px;
}

.generate-pdf-button {
  background-color: #17a2b8;
  color: white;
  margin-bottom: 10px;
}

.download-pdf-link {
  display: inline-block;
  padding: 12px 20px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.download-pdf-link:hover {
  background-color: #1e7e34;
}

.reset-button {
  /* ... other styles ... */
  background-color: #d3d3d3; /* Light gray background */
  color: #333; /* Black font color */
  padding: 0.75rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  max-width: 300px;
  border-radius: 0.5rem;
  margin-top: 1.25rem;
  
}
.stop-button {
  /* ... other styles ... */
  background-color: #d3d3d3; /* Light gray background */
  color: #333; /* Black font color */
  padding: 0.75rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  max-width: 300px;
  border-radius: 0.5rem;
  margin-top: 1.25rem;
  
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Albert Sans', sans-serif;
  background-color: #ffffff;
  color: var(--text-color);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
  max-width: var(--max-content-width);
  margin: 0 auto;
}

.banner-wrapper {
  width: 100%;
  max-width: var(--max-content-width);
  height: 200px;
  overflow: hidden;
  position: relative;
  margin-bottom: 1rem;
}
.popup-container {
  position: fixed; /* Stay in place even if the page scrolls */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the popup */
  z-index: 1000; /* Ensure the popup is on top of other elements */
}
/* Style for the welcome message */
.welcome-message {
  font-size: 2.5rem; /* Customize the font size */
  font-weight: bold; /* Optional: makes the text bold */
  color: #333; /* Customize the text color */
  margin-bottom: 10px;
  text-align: left; /* Align the text to the left */
  position: relative; /* Position it absolutely within the container */
  left: 0; /* Position it on the left side of the screen */
  top: 20px; /* Adjust the top position as needed */
  padding-left: 0px!important; /* Add padding for a bit of space from the edge */
  margin: 0; /* Remove any margins */
}
/* ... other styles ... */
.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.main-title {
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0.5rem;
}

.sub-title {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1.5rem;
}

.content-box {
  max-width: var(--max-content-width);
  padding: 0rem;
  border: none;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem; 
}

/* Recording Section Styles */
.recording-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.recording-box {
  text-align: center;
}

.live-recording-text {
font-size: 14px;
    color: #02293B;
    margin-bottom: 10px;
    font-weight: bold;
}
.sound-wave {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; /* Espacement entre les barres */
  min-height: 78px!important;
}

.sound-bar {
  width: 6px; /* Largeur des barres */
  height: 20px; /* Hauteur initiale */
  background: linear-gradient(to top, #3B919B, #164F60);
  border-radius: 3px; /* Coins arrondis */
  animation: wave-animation 1.5s ease-in-out infinite;
}

/* Définir des délais d'animation pour chaque barre */
.sound-bar:nth-child(1) {
  animation-delay: 0s;
}
.sound-bar:nth-child(2) {
  animation-delay: 0.2s;
}
.sound-bar:nth-child(3) {
  animation-delay: 0.4s;
}
.sound-bar:nth-child(4) {
  animation-delay: 0.6s;
}
.sound-bar:nth-child(5) {
  animation-delay: 0.8s;
}

/* Animation pour faire bouger les barres */
@keyframes wave-animation {
  0%, 100% {
      height: 20px;
  }
  50% {
      height: 50px;
  }
}

/* Point rouge pour montrer l'enregistrement */
.recording-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  animation: blink 1s steps(1, end) infinite;
}

@keyframes blink {
  0%, 100% {
      opacity: 1;
  }
  50% {
      opacity: 0;
  }
}

.recording-time {
  font-size: 24px;
  color: #333; 
  margin-top: 10px;
}

.recording-dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}

.buttons-container {
  display: flex; 
  justify-content: center; 
  margin-top: 10px;
}


.input-group {
  width: 100%;
  margin-bottom: 1rem;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  font-size: 1rem;
}

.footer-text {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin: 1.25rem 0;
}

.button {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  width: 100%;
  max-width: 400px;
  border-radius: 0.5rem;
  margin-top: 1.25rem;
}

.step-container {
  width: 100%;
  max-width: 600px;
  margin-bottom: 1.5rem;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.step-number-inactive {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #BFDAE6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  color: white;
  font-weight: bold;
}

.step-number-active {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #096F9F;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  color: white;
  font-weight: bold;
}

.step-title {
  font-size: 1rem;
  font-weight: normal;
}

@media (min-width: 768px) {
  .container {
    padding: 2rem;
  }
  .form {
    width: 70%;
  }
  .main-title {
    font-size: 2.5rem;
  }
  .sub-title {
    font-size: 1.2rem;
  }
  .step-title {
    font-size: 1.1rem;
  }
}

@media (max-width: 576px) {
  .container {
    padding: 0.5rem;
  }
  .form {
    width: 95%;
  }
  .input-field, .button {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
  .main-title {
    font-size: 2.4rem;
  }
  .sub-title {
    font-size: 0.9rem;
  }
  .step-title {
    font-size: 0.9rem;
  }
}

.container {
  padding: 0rem!important;
}

.my-body{
  padding: 30px;
}

.record-button{
  color: white;
  background-color: #096F9F!important;
  width: 100%;
  padding: 0.8rem!important;
  border-radius: 8px;
  box-shadow: none!important;
}

.recording-box{
  width: 100%;
  background-color: #F4F7FA;
  padding: 20px;
  border-radius: 8px;
}

.feedback-button{
  position: fixed;
  right: -30px;
  top: 50%;
  /* transform: translateY(-50%); */
  z-index: 1000;
  /* transform: rotate(90deg); */
  color: #ffffff;
  background-color: #096f9f;
  border: none;
  padding: 0.6rem;
  font-weight: bold;
  border-radius: 0px 0px 9px 9px;
  transform: translateY(-50%) rotate(90deg);
  font-size: 16px;
}

.loading-box{
  text-align: center;
}